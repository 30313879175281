import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage.tsx';
import AllProducts from './components/Product page/AllProducts.tsx';
import AboutusPage from './components/About-us/AboutusPage.tsx';

function App() {

  const ScrollToTop = () => {
    const history = useHistory();

    useEffect(() => {
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      };
    }, [history]);

    return null;
  };

  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/Products" component={AllProducts} />
          <Route exact path="/AboutUs" component={AboutusPage} />
        </Switch>
      </Router>

    </>
  );
}

export default App;
