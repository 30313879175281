export const banner = require("../src/Assets/banner.png");
export const banner2 = require("../src/Assets/industry.png");
export const banner4 = require("../src/Assets/banner4.png");
export const logo = require("../src/Assets/logo3.png");
export const logo2 = require("../src/Assets/logo3.png");
export const product1 = require("../src/Assets/product1.png");
export const product2 = require("../src/Assets/product2.png");
export const product3 = require("../src/Assets/product3.png");
export const product4 = require("../src/Assets/product4.png");
export const product5 = require("../src/Assets/product5.png");
export const product6 = require("../src/Assets/product6.png");
export const product7 = require("../src/Assets/product7.png");
export const product8 = require("../src/Assets/product8.png");
export const product10 = require("../src/Assets/product10.jpg");
export const product11 = require("../src/Assets/product11.jpg");
export const product12 = require("../src/Assets/product12.jpg");
export const product13 = require("../src/Assets/product13.jpg");
export const product14 = require("../src/Assets/product14.jpg");
export const product15 = require("../src/Assets/product15.jpg");
export const product16 = require("../src/Assets/product16.jpg");
export const product17 = require("../src/Assets/product17.jpg");
export const product18 = require("../src/Assets/product18.jpg");
export const product20 = require("../src/Assets/product20.png");
export const product21 = require("../src/Assets/product21.png");
export const product22 = require("../src/Assets/product22.png");
export const product23 = require("../src/Assets/product23.png");
export const product24 = require("../src/Assets/product24.png");
export const productBanner = require("../src/Assets/productBanner.png");
export const team = require("../src/Assets/Team.png");
export const footerBanner = require("../src/Assets/FooterBanner.png");
export const potato = require("../src/Assets/potato.png");
export const tomato = require("../src/Assets/tomato.png");
export const whatsappIcon = require("../src/Assets/whatsappicon.png");
export const certificate = require("../src/Assets/certificate.png");
export const promises= require("../src/Assets/promises.png");
export const mission= require("../src/Assets/mission5.png");
export const vission= require("../src/Assets/vission5.png");

