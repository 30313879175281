import React, { useEffect, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { certificate } from "../../Assets.tsx";
gsap.registerPlugin(ScrollTrigger);

const Certificate = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapCertificateSection .gsapCertificateCard", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapCertificateSection .gsapCertificateCard",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapCertificateSection h1", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapCertificateSection h1",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  return (
    <div
      style={{ height:"100vh" }}
      className="fullWidth  gsapCertificateSection"
    >
      <h1
        style={{
          textAlign: "center",
          fontSize: "50px",
          color: "#0d803f",
          margin: "50px ",
        }}
      >
        <span style={{ color: "#a6ce39" }}>Awards</span> & Recognitions
      </h1>
      <div style={{ ...webStyle.container, flexWrap: "wrap" }} className="fullWidth">
        <div
          className="promiseCard visionSectionCards gsapCertificateCard"
        >
          <div>
            <img
              src="https://marketplace.canva.com/EAFh7cFx9So/4/0/1600w/canva-white-and-gold-certificate-of-appreciation-phefkzBYa-A.jpg"
              style={{
                width: "400px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                border: "1px solid green",
                borderRadius:"5px",

              }}
              className="certificateImg"
            />
          </div>
        </div>
        <div
          style={{ ...webStyle.middleCard, flexDirection: "column" }}
          className="promiseCard visionSectionCards gsapCertificateCard"
        >
          <div >
            <img
              src={certificate}
              style={{  
                width: "400px",
                height: "550px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                border:"1px solid green"
              }}
              className="certificateImg"
            />
          </div>
        </div>
        <div
          className="promiseCard visionSectionCards gsapCertificateCard"
        >
          <div>
          <img
              src="https://marketplace.canva.com/EAFNlUJs5g4/2/0/1600w/canva-white-simple-certificate-of-appreciation-Fcz7KkZ5YaU.jpg"
              style={{
                width: "400px",
                borderRadius:"5px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                border: "1px solid green",
              }}
              className="certificateImg"
            />

            {/* <img
              src="https://marketplace.canva.com/EAFNlUJs5g4/2/0/1600w/canva-white-simple-certificate-of-appreciation-Fcz7KkZ5YaU.jpg"
              style={{ width: "100%" }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const webStyle = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "50vh",
  },
  paragraph: {
    borderTop: "1px solid black",
    padding: "10px",
    fontSize: "16px",
    fontWeight: 500,
    color: "grey",
  },
  title: {
    fontWeight: 700,
    color: "white",
    fontSize: "30px",
  },
  titleDiv: {
    borderRadius: "20px",
    textAlign: "center",
    height: "150px",
    width: "300px",
    background: "green",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
  },
  middleCard: {
    // width: "350px",
    height: "auto",
    // background: "green",
    borderRadius: "6px",
    margin: "0 50px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    // border: "1px solid black",
    // padding: "30px",
  },
  card: {
    // width: "350px",
    height: "auto",
    // background: "green",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    border: "1px solid green",
    padding: "30px",
  },
};

export default Certificate;
