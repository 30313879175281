import React from "react";
import Footer from "../Footer/Footer.tsx";
import Navbar from "../Navbar/Navbar.tsx";
import ProductCarousel from "../Product page/ProductsCarousel.tsx";
import BannerCarousel from "./BannerCarousel.tsx";
import "../common-component/slick.css";
import AboutSection from "../About-us/AboutSection.tsx";
import MissionAndVission from "../Mission/MissionAndVission.tsx";
import Team from "../Team/Team.tsx";
import WhatsAppButton from "../common-component/WhatsAppButton.tsx";
import Certificate from "../Certificate/Certificate.tsx";


const HomePage = () => {
  return (
    <div>
      <Navbar />
      <BannerCarousel />
      <ProductCarousel />
      <AboutSection />
      <Certificate />
      <MissionAndVission/>
      <WhatsAppButton phoneNumber={"919422041254"} />
      <Footer />
    </div>
  );
};

export default HomePage;
