import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./ProductsCarousel.css";
import {
  product10,
  product11,
  product12,
  product13,
  product14,
  product15,
  product16,
  product17,
} from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

interface Item {
  image: string;
  name: string;
}

const products: Item[] = [
  { image: product14, name: "Zinc-Edta" },
  { image: product10, name: "Boron" },
  { image: product16, name: "Copper-Edta" },
  { image: product17, name: "Calcium-Edta" },
  { image: product13, name: "Manganese-Edta" },
  { image: product11, name: "Ferrous-Eddha" },
  // { image: product15, name: "Eddha-Fe" },
  // { image: product12, name: "Chelated Micronutrient" },
];

const ProductCarousel: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [items] = useState<Item[]>(products);
  const [items2] = useState<Item[]>(products);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapHeading h1", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapHeading h1",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapHeading p", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapHeading p",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 7000);
    return () => clearInterval(timer);
  }, [currentSlide]);

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide2();
    }, 5000);
    return () => clearInterval(timer);
  }, [currentSlide2]);

  const itemsPerSlide = 3;
  const totalSlides = Math.ceil(items.length / itemsPerSlide);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const nextSlide2 = () => {
    setCurrentSlide2((prevSlide) => (prevSlide + 1) % totalSlides2);
  };

  const prevSlide2 = () => {
    setCurrentSlide2(
      (prevSlide) => (prevSlide - 1 + totalSlides2) % totalSlides2
    );
  };

  const getSlideItems = () => {
    const startIndex = currentSlide * itemsPerSlide;
    return items.slice(startIndex, startIndex + itemsPerSlide);
  };

  const itemsPerSlide2 = 1;
  const totalSlides2 = Math.ceil(items2.length / itemsPerSlide2);
  const getSlideItems2 = () => {
    const startIndex2 = currentSlide2 * itemsPerSlide2;
    return items2.slice(startIndex2, startIndex2 + itemsPerSlide2);
  };

  const handleOpenDialog = (item: Item) => {
    setSelectedItem(item);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedItem(null);
  };

  return (
    <div
      className="productSection fullWidth gsapHeading"
    >
      <h1
        style={{
          textAlign: "center",
          fontSize: "50px",
          color: "#0d803f",
          margin: "40px 0 10px 0 ",
        }}
      >
        <span style={{ color: "#a6ce39" }}>Our</span> Products
      </h1>
      <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>

      <p style={{ textAlign: "center", fontSize: "22px", color: "black",margin:"0",width:"50%",fontWeight:500 }} className="fullWidth">
        Our product offers premium chelated micronutrients for optimal bamboo
        growth and sustainable cultivation. <span style={{color: "#0d803f",cursor:"pointer"}} onClick={()=>{history.push("Products")}}>More Products</span>
      </p>
      </div>
      <div id="products" className="carousel-container5 our-product-container" >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
          className="desktop-view"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover"
          >
            <div
              style={{
                cursor: "pointer",
                fontSize: "48px",
                color: "black",
                marginBottom: "5px",
              }}
              className="scroll-arrow"
              id="scroll-left"
              onClick={prevSlide}
            >
              &#8592;
            </div>
          </div>

          <div className="carousel5" onClick={()=>{history.push("Products")}}>
            {getSlideItems().map((item, index) => (
              <div
                className="card"
                key={index}
                onClick={() => handleOpenDialog(item)}
              >
                <div className="wrapper">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="cover-image"
                  />
                </div>
                <img src={item.image} alt={item.name} className="character" />
                <div className="product-name">{item.name}</div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover"
          >
            <div
              style={{
                cursor: "pointer",
                fontSize: "48px",
                color: "black",
                marginBottom: "5px",
              }}
              className="scroll-arrow"
              id="scroll-right"
              onClick={nextSlide}
            >
              &#8594;
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="mobile-view"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover"
          >
            <div
              style={{
                cursor: "pointer",
                fontSize: "48px",
                color: "black",
                marginBottom: "5px",
              }}
              className="scroll-arrow"
              id="scroll-left"
              onClick={prevSlide2}
            >
              &#8592;
            </div>
          </div>

          <div className="carousel5">
            {getSlideItems2().map((item, index) => (
              <div
                className="card"
                key={index}
                onClick={() => handleOpenDialog(item)}
              >
                <div className="wrapper">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="cover-image"
                  />
                </div>
                <img src={item.image} alt={item.name} className="character" />
                <div className="product-name">{item.name}</div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover"
          >
            <div
              style={{
                cursor: "pointer",
                fontSize: "48px",
                color: "black",
                marginBottom: "5px",
              }}
              className="scroll-arrow"
              id="scroll-right"
              onClick={nextSlide2}
            >
              &#8594;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
