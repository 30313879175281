import React, { useEffect, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import XIcon from "@mui/icons-material/X";
import { useHistory } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { footerBanner, logo, potato, tomato } from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const history = useHistory();

  const handleContact = () => {
    window.location.href = "tel:9422041254";
  };
  const handleWhatsApp = () => {
    const phoneNumber = "+919822419880";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  useGSAP(() => {
    if (!isMobile) {

    gsap.from(".gsapFooterSection", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".gsapFooterSection",
        scroller: "body",
        markers: false,
        start: "top 80%",
      },
    });
  }
  });

  useGSAP(() => {
    if (!isMobile) {

    gsap.from(".gsapFooterSection2", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".gsapFooterSection2",
        scroller: "body",
        markers: false,
        start: "top 65%",
      },
    });
  }
  });

  useGSAP(() => {
    if (!isMobile) {
    gsap.from(".gsapFooterLogo", {
      opacity: 0,
      duration: 1,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".gsapFooterLogo",
        scroller: "body",
        markers: false,
        start: "top 65%",
      },
    });
  }
  });

  return (
    <div
      style={{
        ...webStyle.Container,
        flexDirection: "column",
        position: "relative",
      }}
      className=" fullWidth"
    >
      <div className=" fullWidth">
        <div>
          <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

          <img
            src={logo}
            style={webStyle.logo}
            onClick={() => {
              history.push("/");
            }}
            className="gsapFooterLogo"
            />
            </div>

          <div style={webStyle.childContainer} className="FooterContainer gsapFooterSection">
            <div
              style={{
                width:"100%",
                display:"flex",
                alignContent:"center",
                justifyContent:"space-evenly",
                textTransform: "capitalize",
              }}
              className="FooterSection"
              
            >
              <div style={{display:"flex"}} className="FooterSection" >

              <span style={webStyle.title}>FOLLOW US ON</span>
              <div style={{marginLeft:"30px"}} className="callUsFooter">
                <FacebookIcon
                  style={webStyle.socialMediaIcon}
                  className="RouteOptions2"
                />
                <InstagramIcon
                  style={webStyle.socialMediaIcon}
                  className="RouteOptions2"
                />
                <TwitterIcon
                  style={webStyle.socialMediaIcon}
                  className="RouteOptions2"
                />
                <XIcon
                  className="RouteOptions2"
                  style={{ color: "black", fontSize: "30px" }}
                />
              </div>
              </div>
              <div style={{display:"flex",marginLeft:"20px",borderLeft:"2px solid grey",borderRight:"2px solid grey",padding:"0px 20px"}} className="FooterSection callUsFooter">

              <span style={{...webStyle.contactTitle}} onClick={handleContact}>call us </span>
              <span
                style={{...webStyle.contactText,marginLeft:"20px"}}
                className="RouteOptions2 callUsFooter"
                onClick={handleContact}
                >
                +91 9422041254
              </span>
                </div>
                <div style={{display:"flex",marginLeft:"20px",padding:"0px 20px"}} className="FooterSection callUsFooter">

              <span style={{...webStyle.contactTitle}} onClick={handleWhatsApp}>Whatsapp us </span>
              <span
                style={{...webStyle.contactText,marginLeft:"20px"}}
                className="RouteOptions2 callUsFooter"
                onClick={handleWhatsApp}
                >
                 +91 9822419880
              </span>
                </div>
            </div>
          </div>
            <div
              style={webStyle.childContainer}
              className="FooterSection gsapFooterSection locationContainer"
            >
              <span style={webStyle.title}> Address</span>
              <a
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                href="https://www.google.com/maps/search/House+No.+12,+Shantai+Banglow,+New+Nurses+Town+Society,+Dhankawadi,+Pune-411043/@18.4725546,73.8282337,13.49z?entry=ttu"
              >
                <div
                  style={{ width: "80%", display: "flex" }}
                  className="RouteOptions2"
                >
                  <LocationOnIcon />
                  <div className="RouteOptions2">
                    <span style={{ fontSize: "18px" }}>
                      Sr.No.52/5, Flat No 503,Vasantsakha Plaza,Near Navale
                      Bridge, Narhe, Pune-411041.
                    </span>
                  </div>
                </div>{" "}
              </a>
            </div>
          <div className="wheat-icons">
            <img src={tomato} alt="leaf1" className="wheat-icon wheat-icon1" />
            <img src={tomato} alt="leaf2" className="wheat-icon wheat-icon2" />
            <img src={tomato} alt="leaf3" className="wheat-icon wheat-icon3" />
            <img src={potato} alt="leaf4" className="wheat-icon wheat-icon4" />
          </div>
        </div>
      </div>
      <p
        className="copyRightstyle"
        style={{
          ...webStyle.copyRightText,
          position: "relative",
          textAlign: "center",
        }}
      >
        2024 © Green Gold Industries Pvt. Ltd. All rights reserved.
      </p>
    </div>
  );
};

const webStyle = {
  copyRightText: {
    width: "100%",
    paddingBottom: "30px",
    top: "150px",
    color: "gray",
    fontWeight: 400,
    fontSize: "12px",
  },
  logo: {
    width: "400px",
    marginBottom: "60px",
    cursor: "pointer",
    // border: "1px solid black",
    borderRadius: "8px",
  },
  Container: {
    height: "100vh",
    width: "100%",
    background: `url(${footerBanner}) no-repeat center center/cover`,
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0px 20px",
    overflow: "hidden",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    color: "black",
  },
  contactTitle: {
    fontWeight: 600,
    color: "black",
    fontSize: "18px",
  },
  contactText: {
    fontWeight: 600,
    color: "black",
    cursor: "pointer",
  },
  socialMediaIcon: {
    color: "black",
    marginRight: "10px",
    fontSize: "30px",
  },
  SectionsContainer: {
    display: "flex",
    lineHeight: 1.5,
    // width: "100%",
  },
  childContainer: {
    display: "flex",
    justifyContent: "space-around",
    padding: "30px 100px 0px 100px",
  },
};

export default Footer;
