import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { logo } from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const PDF_FILE_URL = "/Green Gold.pdf";

const Navbar = () => {
  const viewPDF = (url) => {
    window.open(url, "_blank");
  };
  const [isSidebarVisible, setSidebarVisibility] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [navbarVisible, setNavbarVisible] = useState(true);

  const openMenu = () => {
    setSidebarVisibility((prev) => !prev);
    document.body.style.overflow = isSidebarVisible ? "unset" : "hidden";
  };
  

  const history = useHistory();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setNavbarVisible(false);
      } else {
        setNavbarVisible(true);
      }
      setLastScrollY(window.scrollY);
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      document.body.style.overflow = "unset";
      setSidebarVisibility(false);
    });
    return () => {
      unlisten(); 
    };
  }, [history]);
  

  const webStyle = {
    container: {
      backgroundColor: "#1F1F1F",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "3px 0 0 0",
      height: "auto",
      width: "100%",
      zIndex: "1111",
    },
    MenuButtonStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "white",
      border: "none",
      color: "black",
    },
    logo: {
      height: "70px",
      cursor: "pointer",
      marginBottom: "4px",
      borderRadius: "8px",
      background:"white"
    },
    searchBar: {
      width: "100%",
      height: "40px",
      borderRadius: "5px",
      border: "2px solid #be9449",
    },
    options: {
      padding: "15px",
      fontWeight: 600,
      borderBottom: "1px solid grey",
      cursor: "pointer",
    },
    options2: {
      padding: "15px",
      fontWeight: 600,
      borderBottom: "1px solid grey",
      cursor: "pointer",
      color: "white",
    },
    optionsDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      gap: "12px",
    },
    formLogo: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      display: "flex",
      alignSelf: "center",
      marginBottom: "20px",
    },
    formDiv: {
      display: "flex",
      flexDirection: "column",
    },
    sendOtpButton: {
      color: "white",
      padding: "5px",
      background: "#565e6b",
      borderRadius: "3px",
    },
    enterOtpInput: {
      marginBottom: "10px",
      padding: "10px",
      borderRadius: "3px",
    },
    HeadingOption: {
      marginRight: "20px",
      cursor: "pointer",
      textDecoration: "none",
      color: isScrolled ? "white" : "black",
    },
    dropdown: {
      position: "absolute",
      backgroundColor: "white",
      border: "1px solid #ccc",
      width: "100%",
      maxHeight: "200px",
      overflowY: "auto",
      zIndex: 1000,
      textDecoration: "none",
    },
    dropdownItem: {
      padding: "10px",
      cursor: "pointer",
      display: "flex",
      textTransform: "capitalize",
      alignItems: "center",
      fontSize: "14px",
    },
    itemImage: {
      width: "40px",
      height: "40px",
      marginRight: "10px",
    },
  };

  const handleHomeclick = () => {
    setSidebarVisibility(false);
    history.push("/");
  };
  const handleProductclick = () => {
    setSidebarVisibility(false);
    history.push("/Products");
  };
  const handleAboutusclick = () => {
    setSidebarVisibility(false);
    history.push("/AboutUs");
  };
  const [openSection, setOpenSection] = useState(null);

  const handleSectionClick = (sectionName) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === sectionName ? null : sectionName
    );
  };
  const handleContact = () => {
    window.location.href = "tel:+919422041254";
  };
  const handleEmail = () => {
    window.location.href = "mailto:greengoldindustries2020@gmail.com";
  };
  const handleWhatsApp = () => {
    const phoneNumber = "+919422041254";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  const handleMouseEnter = (section) => {
    setHover((prevHover) => ({ ...prevHover, [section]: true }));
  };

  const handleMouseLeave = (section) => {
    setHover((prevHover) => ({ ...prevHover, [section]: false }));
  };

  const [hover, setHover] = useState({
    products: false,
    AboutUs: false,
    contactUs: false,
    Downloads: false,
  });

  useGSAP(() => {
    gsap.from(".gsaplogo", {
      y: -30,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  useGSAP(() => {
    gsap.from(".gsapRightSideNav", {
      y: -30,
      opacity: 0,
      duration: 1,
      stagger: 0.3,
      delay: 1,
    });
  });


  return (
    <div>
      <div
  className={`NavbarContainer ${navbarVisible ? 'navbar-visible' : 'navbar-hidden'} ${isScrolled ? 'navbar-scrolled' : ''}`}
        style={{
          ...webStyle.container,
          top: navbarVisible ? "0" : "-100px",
          position: "fixed",
          transition: "top 0.3s ease-in-out", 
          backgroundColor: isScrolled ? "rgba(52, 163, 41, 0.77)" : "#0d803f",
          color: isScrolled ? "white" : "black",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "58%",
            justifyContent: "space-between",
            padding: "0 40px",
          }}
          className="navbarLogoContainer"
        >
          <img
            onClick={() => history.push("/")}
            src={logo}
            style={webStyle.logo}
            className="logo gsaplogo"
            alt="logo"
          />
        </div>
        <Box
          className="rightSideNavOptions gsapRightSideNav"
          style={{ ...webStyle.optionsDiv, padding: "0 5px" }}
        >
          <div className="dropdown gsapRightSideNav">
            <span
              onClick={() => history.push("/")}
              className="items RouteOptions"
              style={webStyle.HeadingOption}
            >
              Home
            </span>
          </div>
          <div className="dropdown gsapRightSideNav">
            <span
              onClick={() => history.push("/Products")}
              className="items RouteOptions"
              style={webStyle.HeadingOption}
            >
              Products
            </span>
          </div>

          <div className="dropdown gsapRightSideNav">
            <span
              onClick={() => history.push("/AboutUs")}
              className="items RouteOptions"
              style={webStyle.HeadingOption}
            >
              About Us
            </span>
          </div>

          <li className="dropdown gsapRightSideNav">
            <a
              href=""
              className="items RouteOptions"
              style={{
                ...webStyle.HeadingOption,
                display: "flex",
                alignItems: "center",
              }}
              onMouseEnter={() => handleMouseEnter("contactUs")}
              onMouseLeave={() => handleMouseLeave("contactUs")}
            >
              Contact us
              {hover.contactUs ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </a>
            <ul
              className="dropdown-menu"
              onMouseEnter={() => handleMouseEnter("contactUs")}
              onMouseLeave={() => handleMouseLeave("contactUs")}
            >
              <li className="dropdown-submenu">
                <a onClick={handleContact} href="">
                  Call Us
                </a>
              </li>
              <li className="dropdown-submenu">
                <a onClick={handleWhatsApp} href="">
                  Whatsapp Us
                </a>
              </li>
              <li className="dropdown-submenu">
                <a onClick={handleEmail} href="">
                  Email
                </a>
              </li>
            </ul>
          </li>
          <div onClick={() => viewPDF(PDF_FILE_URL)} className="DownloadBtn gsapRightSideNav">
            <span style={{ color: "#FFFFFF", fontSize: "20px" }}>
              Download Profile
            </span>
            <div className="btn2"></div>
          </div>
        </Box>
        <Box className="item  humburger-menu">
          <MenuIcon
            onClick={openMenu}
            style={{
              fontSize: "35px",
              marginRight: "20px",
              color: "white",
            }}
            className="gsapRightSideNav"
          />
        </Box>
        {/* Hamburger Start */}
        {isSidebarVisible && (
          <Box className="sidebar">
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-between",
                width: "100%",
                margin: "12px",
              }}
            >
              <img
                onClick={() => history.push("/")}
                src={logo}
                style={webStyle.logo}
                className="logo"
                alt="logo"
              />
              <CloseIcon
                onClick={() => {
                  setSidebarVisibility(false);
                  document.body.classList.remove("no-scroll");
                  document.body.style.overflow = "unset"; 
                }}
                style={{
                  marginRight: "15px",
                  fontSize: "50px",
                  color: "white",
                }}
              />
            </div>

            <Box style={{ width: "100%" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  lineHeight: "2.5",
                  width: "100%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <a
                        style={{
                          ...webStyle.HeadingOption,
                          fontWeight: 600,
                          borderBottom: "1px solid grey",
                          padding: "15px",
                          margin: "0",
                          color: "white",
                          textDecoration: "none",
                        }}
                        href="/"
                      >
                        <span
                          onClick={() => {
                            handleHomeclick();
                          }}
                        >
                          Home{" "}
                        </span>
                      </a>
                      <a
                        style={{
                          ...webStyle.HeadingOption,
                          fontWeight: 600,
                          borderBottom: "1px solid grey",
                          padding: "15px",
                          margin: "0",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        <span
                          onClick={() => {
                            handleProductclick();
                          }}
                        >
                          Products
                        </span>
                      </a>
                      <a
                        style={{
                          ...webStyle.HeadingOption,
                          fontWeight: 600,
                          borderBottom: "1px solid grey",
                          padding: "15px",
                          margin: "0",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        <span
                          onClick={() => {
                            handleAboutusclick();
                          }}
                        >
                          About Us
                        </span>
                      </a>
                      <p
                        onClick={() => handleSectionClick("Contact Us")}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "white",
                          justifyContent: "space-between",
                          padding: "15px",
                          border: "none",
                          borderBottom: "1px solid grey",
                          margin: "0",
                        }}
                      >
                        <div>
                          <span style={{ fontWeight: 600 }}> Contact Us</span>
                        </div>
                        <span>
                          {openSection === "Contact Us" ? (
                            <KeyboardArrowUpIcon style={{ fontSize: "15px" }} />
                          ) : (
                            <KeyboardArrowDownIcon
                              style={{ fontSize: "15px" }}
                            />
                          )}
                        </span>
                      </p>
                      {openSection === "Contact Us" && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                          className="scrollable-section"
                        >
                          <a
                            className="options"
                            style={{
                              ...webStyle.options2,
                              textDecoration: "none",
                            }}
                            onClick={handleContact}
                          >
                            Call Us
                          </a>
                          <a
                            className="options"
                            style={{
                              ...webStyle.options2,
                              textDecoration: "none",
                            }}
                            onClick={handleWhatsApp}
                          >
                            Whatsapp Us
                          </a>
                          <a
                            className="options"
                            style={{
                              ...webStyle.options2,
                              textDecoration: "none",
                            }}
                            onClick={handleEmail}
                          >
                            Email Us
                          </a>
                        </div>
                      )}{" "}
                      <a
                        style={{
                          ...webStyle.HeadingOption,
                          fontWeight: 600,
                          borderBottom: "1px solid grey",
                          padding: "15px",
                          margin: "0",
                          color: "white",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          setSidebarVisibility(false);
                          viewPDF(PDF_FILE_URL);
                        }}
                      >
                        <span>Download Profile</span>
                      </a>
                    </div>
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </div>
    </div>
  );
};

export default Navbar;
