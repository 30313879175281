import React from 'react';
import { whatsappIcon } from '../../Assets.tsx';

interface WhatsAppButtonProps {
  phoneNumber: string;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phoneNumber }) => {
  const handleWhatsAppClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div 
    onClick={handleWhatsAppClick} 
    className="whatsapp-button" 
    style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      cursor: 'pointer',
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '1111111'
    }}
  >
    <img src={whatsappIcon} style={{width:"100px"}} />
    </div>
  );
};

export default WhatsAppButton;
