import React from "react";
import {
  product13,
  product14,
  product16,
  product17,
  product18,
  productBanner,
} from "../../Assets.tsx";
import Navbar from "../Navbar/Navbar.tsx";
import Footer from "../Footer/Footer.tsx";
import ProductDetails from "./ProductDetails.tsx";

const AllProducts = () => {

  const productItems = [
    {
      product: "EDTA Zinc 12%",
      productName: "Zinc EDTA Chelate /Zinc EDTA/ Chelated Zinc",
      physicalForm: "Physical form Powder",
      chemicalFormula: "C10H12 N2 O8 Zn. 2 Na",
      chemicalName: "Ethylenediaminetetraacetic acid zinc, Edetate zinc",
      casNumber: "14025-21-9",
      color: "Off White",
      molecularWeight: "435",
      assay: "Min 99%",
      zincContent: "12% as Zn",
      pH: "6.0 (+ / -1)",
      application: [
        "Applied foliar or as soil application, ensures the plant gets the maximum amount of needed Zinc",
        "Ensures the maximal utilization of Zinc, irrespective of the physical and chemical characteristics of the soil.",
      ],
      packing: "25 HDPE bag with liner inside.",
      specificationsHeader:
        "  Leading manufacturer and exporter of EDTA Zinc/ Chelated Zinc Chelated Zinc (Zinc EDTA 12%) - Specifications:",
    },
    {
      product: "EDTA Calcium 10%",
      productName: "EDTA Calcium / Chelated Calcium",
      chemicalName:
        "Ethylenediaminetetraacetic acid, Calcium-disodium complex , Edetate calcium disodium; Calcium disodium ethylenediaminetetraacetate",
      casNumber: "62-33-9",
      colorPhysical: "Off White Powder",
      activeIngredient: "CaNa2 EDTA",
      molecularWeight: "410.20",
      assay: "98 - 102%",
      zincContent: "12% as Zn",
      caclciumContent: "10% (min.) as Ca",
      pH: "6.0 (+ / -1)",
      application: [
        "Prevents and correct deficiencies which are caused due to a lack of or imbalance in the assimilation of Calcium.",
        "Strengthens the stability of cellular film and amphithecium.",
      ],
      packing: "25 HDPE bag with liner inside.",
      specificationsHeader:
        "Leading manufacturer and exporter of EDTA Calcium /Chelated Calcium (EDTA Calcium) Specifications: ",
    },
    {
      product: "EDTA Magnesium",
      productName:
        "Magnesium EDTA chelate / Magnesium EDTA / Chelated Magnesium",
      physicalForm: "Powder",
      chemicalName:
        "Ethylenediaminetetraacetic acid magnesium, Edetate magnesium, Magnesium ethylenediaminetetraacetate",
      casNumber: "14402-88-1",
      colorPhysical: "Off White Powder",
      activeIngredient: "6.0 % (+/- 0.5) as Mg",
      molecularWeight: "358.5",
      assay: "98 - 102%",
      pH: "6.80",
      application: [
        "Recommended to use as plant nutrient supplements for correction and prevention of mg deficiency",
        "Applied directly to the soil as powder or spray or through dip or injection system or foliar application",
        "Feed additives",
      ],
      packing: "25 HDPE bag with liner inside.",
      specificationsHeader:
        "Leading manufacturer and exporter of EDTA Magnesium/ Chelated Magnesium Chelated Magnesium - (EDTA Magnesium- 6%) Specifications: ",
    },
    {
      product: "EDTA Manganese",
      productName:
        "Manganese EDTA chelate / Manganese EDTA / Chelated Manganese",
      physicalForm: "Powder",
      chemicalName:
        "Ethylenediaminetetraacetic acid magnesium, Edetate magnesium, Magnesium ethylenediaminetetraacetate",
      casNumber: "15375-84-5",
      colorPhysical: "White to slightly pinkish powder",
      activeIngredient: "12% as Mn",
      molecularWeight: "425",
      pH: "6.0 (+ / -1)",
      application: [
        "It is a stable water soluble metal chelate.",
        "Mainly used in food industry, agriculture and horticulture as micronutrient.",
        "Prevention and correction of deficiencies caused by nutrient imbalances or lack of Manganese availability.",
      ],
      packing: "25 HDPE bag with liner inside.",
      specificationsHeader:
        "Leading manufacturer and exporter of EDTA Manganese/ Chelated Manganese Chelated Manganese (EDTA Manganese -12%) Specifications: ",
    },
    {
      product: "EDTA Copper 12%",
      productName: "EDTA Copper, Chelated Copper, EDTA CU, Copper EDTA chelate",
      physicalForm: "Blue Powder",
      chemicalName:
        "Ethylenediaminetetraacetic acid,    Zinc-disodium complex, zinc disodium     ethylenediaminetetraacetate    ",
      casNumber: "14025-15-1",
      activeIngredient: "CuNa2 EDTA",
      copperContent: "12% as Cu",
      molecularWeight: "425",
      pH: "6.0 (+ / -1)",
      application: [
        "EDTA Copper is a stable water-soluble metal chelate, mainly used in agriculture and horticulture as a micronutrient, to prevent and correct copper deficiencies.",
        "Applicable in Foliage fertilizers, in trickle irrigation, absorbed in NPK etc.",
        "EDTA Copper provides healthy growth to plant and maximizes crop yield.",
      ],
      packing: "25 kg PP bag with LDPE liner inside.",
      specificationsHeader:
        "Leading manufacturer and exporter of EDTA Copper / Chelated Copper Chelated Copper Specifications:",
    },
  ];

  return (
    <div>
      <Navbar />
      <div style={{ position: "relative", width: "100%" }}>
        <img
          src={productBanner}
          alt="Banner"
          style={{ width: "100%", height: "100vh" }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#36f236",
            fontSize: "60px",
            fontWeight: "bold",
            textAlign: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "10px 20px",
            borderRadius: "5px",
            width: "80%",
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Our Products
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div
          className="fullWidth productPagemargin"
          style={{ ...webStyle.boxShadowDiv, flexWrap: "wrap" }}
        >
          <div style={{ display: "flex" }} className="ProductCard">
            <img
              src={product14}
              alt="product1"
              className="fullWidth"
              style={{ width: "550px" }}
            />
            <ProductDetails productData={productItems[0]} />
          </div>
        </div>
        <div
          className="fullWidth productPagemargin"
          style={{
            ...webStyle.boxShadowDiv,
            flexWrap: "wrap",
            flexDirection: "row-reverse",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row-reverse" }}
            className="ProductCard"
          >
            <img
              src={product16}
              alt="product1"
              className="fullWidth"
              style={{ width: "550px" }}
            />
            <div style={{ marginLeft: "30px" }} className="marginZero">
              <ProductDetails productData={productItems[4]} />
            </div>
          </div>
        </div>

        <div
          className="fullWidth productPagemargin"
          style={{ ...webStyle.boxShadowDiv, flexWrap: "wrap" }}
        >
          <div style={{ display: "flex" }} className="ProductCard">
            <img
              src={product17}
              alt="product1"
              className="fullWidth"
              style={{ width: "550px" }}
            />
            <ProductDetails productData={productItems[1]} />
          </div>
        </div>
        <div
          className="fullWidth productPagemargin"
          style={{
            ...webStyle.boxShadowDiv,
            flexWrap: "wrap",
            flexDirection: "row-reverse",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row-reverse" }}
            className="ProductCard"
          >
            <img
              src={product18}
              alt="product1"
              className="fullWidth"
              style={{ width: "550px" }}
            />
            <div style={{ marginLeft: "30px" }} className="marginZero">
              <ProductDetails productData={productItems[3]} />
            </div>
          </div>
        </div>
        <div
          className="fullWidth productPagemargin"
          style={{ ...webStyle.boxShadowDiv, flexWrap: "wrap" }}
        >
          <div style={{ display: "flex" }} className="ProductCard">
            <img
              src={product13}
              alt="product1"
              className="fullWidth"
              style={{ width: "550px" }}
            />
            <ProductDetails productData={productItems[2]} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const webStyle = {
  BannerImage: {
    marginTop: "70px",
    width: "100%",
  },
  boxShadowDiv: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    width: "80%",
    margin: "50px",
    padding:"50px",
    boxShadow:
      "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
  },
};
export default AllProducts;
