import React from 'react';

interface ProductProps {
  productData: {
    product?: string;
    productName?: string;
    physicalForm?: string;
    chemicalFormula?: string;
    chemicalName?: string;
    casNumber?: string;
    colorPhysical?: string;
    activeIngredient?:string;
    molecularWeight?: string;
    assay?: string;
    zincContent?: string;
    copperContent?:string;
    calciumContent?: string ;
    pH?: string;
    application?: string[];
    packing?: string;
    specificationsHeader?: string; 
  };
}

const ProductDetails: React.FC<ProductProps> = ({ productData }) => {
  return (
    <div style={{ color: 'black' }} className="fullWidthTable">
      <p
        style={{
          color: 'black',
          textAlign: 'left',
          fontWeight: 600,
          fontSize: '16px',
          width: '95%',
        }}
      >
        {productData.specificationsHeader || 'Leading manufacturer and exporter of EDTA Zinc/ Chelated Zinc Chelated Zinc (Zinc EDTA 12%) - Specifications:'}
      </p>
      <table style={{ height: '250px', width: '95%' }} className='fullWidth'>
        {productData.product && (
          <tr style={{ height: '40px', fontWeight: 600,background:"rgba(52, 163, 41, 0.77)",color:"white" }}>
            <td style={{ width: '170px' }}>Product</td>
            <td >{productData.product}</td>
          </tr>
        )}
        {productData.productName && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Product Name</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.productName}</td>
          </tr>
        )}
        {productData.physicalForm && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Physical form</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.physicalForm}</td>
          </tr>
        )}
        {productData.chemicalFormula && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Chemical Formula</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.chemicalFormula}</td>
          </tr>
        )}
        {productData.chemicalName && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Chemical Name</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.chemicalName}</td>
          </tr>
        )}
        {productData.casNumber && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>CAS No.</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.casNumber}</td>
          </tr>
        )}
        {productData.colorPhysical && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Color</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.colorPhysical}</td>
          </tr>
        )}
           {productData.activeIngredient && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Active Ingredient</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.activeIngredient}</td>
          </tr>
        )}
        {productData.molecularWeight && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Molecular Weight</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.molecularWeight}</td>
          </tr>
        )}
        {productData.assay && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Assay</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.assay}</td>
          </tr>
        )}
        {productData.zincContent && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Zinc Content</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.zincContent}</td>
          </tr>
        )}
         {productData.copperContent && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Copper Content</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.copperContent}</td>
          </tr>
        )}
         {productData.calciumContent && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>Calcium Content</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.calciumContent}</td>
          </tr>
        )}
        {productData.pH && (
          <tr style={{ height: '40px',background:"#e8e8e8" }}>
            <td style={{ fontWeight: 600, fontSize: '14px' }}>pH</td>
            <td style={{ fontSize: '14px',color:"black" }}>{productData.pH}</td>
          </tr>
        )}
      </table>
      <div style={{ textAlign: 'left' }}>
        {productData.application && (
          <>
            <p style={{ margin: '10px 0 0 0', fontWeight: 700 }}>Application:</p>
            {productData.application.map((app, index) => (
              <p key={index} style={{ margin: '0', width: '95%', fontSize: '14px',color:"grey" }}>
                {index + 1}. {app}
              </p>
            ))}
          </>
        )}
        {productData.packing && (
          <>
            <p style={{ margin: '10px 0 0 0', fontWeight: 700 }}>Packing:</p>
            <p style={{ margin: '0', fontSize: '14px',color:"grey" }}>{productData.packing}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;
