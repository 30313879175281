import React from "react";
import { Carousel } from "react-bootstrap";
import { banner, banner2, banner4 } from "../../Assets.tsx";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const BannerCarousel = () => {
  useGSAP(() => {
    gsap.from(".gsapRightSideNavCarosel", {
      y: -30,
      opacity: 0,
      duration: 1,
      stagger: 0.3,
      delay: 1,
    });
  });

  return (
    <Carousel fade={true} interval={3000} style={{marginTop:"20px"}} className="gsapRightSideNavCarosel">
      <Carousel.Item style={{ height: "720px" }} className="fullWidth" >
        <img className="d-block w-100" src={banner4} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
         
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "720px" }} className="fullWidth">
        <img className="d-block w-100" src={banner2} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
        
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default BannerCarousel;
